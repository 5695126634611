import React, { Suspense, lazy } from "react";
import { ToastContainer } from "react-toastify";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const Pix = lazy(() => import("./pages/Pix"));
const Order = lazy(() => import("./pages/Order"));
const BankSlip = lazy(() => import("./pages/BankSlip"));
const CreditCard = lazy(() => import("./pages/CreditCard"));
const PageNotFound = lazy(() => import("./pages/PageNotFound"));
const PaymentMethods = lazy(() => import("./pages/PaymentMethods"));
const OpenFinance = lazy(() => import("./pages/OpenFinance"));
const OpenFinanceReview = lazy(() => import("./pages/OpenFinanceReview"));
const PaymentSuccess = lazy(() => import("./pages/PaymentSuccess"));
const PaymentTimeout = lazy(() => import("./pages/PaymentTimeout"));
const OpenFinanceAuthorization = lazy(
  () => import("./pages/OpenFinanceAuthorization"),
);

import { Footer, Header, Spinner } from "./components";
import { SharedDataProvider } from "./providers/SharedDataProvider";
import * as Sentry from "@sentry/react";

const queryClient = new QueryClient();

const FallbackComponent = () => (
  <div>
    <h2>Algo deu errado</h2>
    <p>Estamos trabalhando nisso o mais rapido possivel.</p>
  </div>
);

const AppLayout: React.FC = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Header />
      <Routes>
        <Route path="" element={<Order />} />
        <Route path="PaymentMethods" element={<PaymentMethods />} />
        <Route path="Pix" element={<Pix />} />
        <Route path="Pix/Success" element={<PaymentSuccess />} />
        <Route path="BankSlip" element={<BankSlip />} />
        <Route path="BankSlip/Success" element={<PaymentSuccess />} />
        <Route path="CreditCard" element={<CreditCard />} />
        <Route path="CreditCard/Success" element={<PaymentSuccess />} />
        <Route path="OpenFinance" element={<OpenFinance />} />
        <Route path="OpenFinance/Review" element={<OpenFinanceReview />} />
        <Route
          path="OpenFinance/Authorization"
          element={<OpenFinanceAuthorization />}
        />
        <Route path="OpenFinance/Success" element={<PaymentSuccess />} />
        <Route path="OpenFinance/Timeout" element={<PaymentTimeout />} />
      </Routes>
      <Footer />
    </div>
  );
};

const App: React.FC = () => {
  return (
    <Sentry.ErrorBoundary fallback={<FallbackComponent />}>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<Spinner />}>
          <ToastContainer autoClose={8000} />
          <BrowserRouter>
            <Routes>
              <Route
                path=":token/*"
                element={
                  <SharedDataProvider>
                    <AppLayout />
                  </SharedDataProvider>
                }
              />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </BrowserRouter>
        </Suspense>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
