import React from "react";
import { Icons } from "src/constants";
import { useCheckoutDataContext } from "src/providers/SharedDataProvider";

const Header: React.FC = () => {
  const data = useCheckoutDataContext();
  const topLineBackground =
    data.visualPreferences.layout.colors.topLineBackground;
  const merchantLogo = data.merchant.logo;

  return (
    <header
      className={`flex justify-center border-b-2 bg-color-neutral-soft text-center`}
      style={{
        borderTopColor: topLineBackground,
        borderTopWidth: "3px",
      }}
    >
      <nav
        className={`flex max-w-[1100px] grow items-center justify-between p-6 px-6 lg:px-0`}
      >
        <div className="flex lg:flex-1">
          <img
            src={merchantLogo}
            alt="Pay2b"
            className="h-14 w-auto mix-blend-multiply"
          />
        </div>
        <div className="flex items-center justify-center gap-2 rounded-full bg-black/0 py-2">
          <div className="relative">
            <img src={Icons.Lock} alt="" />
          </div>
          <div className="font-['Public Sans'] hidden text-base font-normal leading-normal text-[#474d55] md:block">
            Área segura
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
