import { Strings } from "src/constants";
import { CurrencyHelper } from "src/utils";

const PaymentMethodInfo: React.FC<{
  method?: string;
  amount: number;
  installmentCount?: number;
  installmentValue?: number;
}> = ({ method, amount, installmentCount, installmentValue }) => {
  const methodName = method ? Strings[method as keyof typeof Strings] : "";
  return (
    <div
      className={`flex w-full items-center justify-between rounded-lg p-4 font-light ${method === "CREDIT_CARD" ? "bg-color-neutral-base-2" : "bg-color-neutral-soft px-0"}`}
    >
      <div>
        <p>{methodName}</p>
        {installmentCount && installmentValue && (
          <p className="text-sm">
            ({installmentCount}x{" "}
            {CurrencyHelper.formatBRCurrency(installmentValue)})
          </p>
        )}
      </div>
      <p>{CurrencyHelper.formatBRCurrency(amount)}</p>
    </div>
  );
};

export default PaymentMethodInfo;
