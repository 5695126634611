import React from "react";

import { Icons, Strings } from "src/constants";
const Footer: React.FC = () => {
  return (
    <footer className="flex items-center justify-center bg-color-neutral-base-2 text-center text-color-neutral-base-4">
      <section className="grid max-w-[1100px] grid-cols-2 items-center gap-4 p-6 md:grid-cols-[6fr_1fr_auto] lg:px-0">
        <img
          src={Icons.Pay2b}
          alt="Pay2b"
          className="col-span-2 place-self-center md:col-span-1 md:place-self-start"
        />
        <div className="text-sm font-normal leading-[21px] text-color-neutral-base-4">
          {Strings.PRIVACY_POLICY}
        </div>
        <div className="text-sm font-normal leading-[21px] text-color-neutral-base-4">
          {Strings.HELP}
        </div>
      </section>
    </footer>
  );
};

export default Footer;
