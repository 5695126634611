import React, { ChangeEvent } from "react";

type InputTextProps = {
  label?: string;
  placeholder?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  value?: string | number | readonly string[];
  icon?: string;
  className?: string;
};

const InputText: React.FC<InputTextProps> = ({
  label,
  placeholder,
  onChange,
  onFocus,
  error,
  value,
  icon,
  className,
  ...rest
}) => {
  return (
    <div className="inline-flex w-full flex-col items-start justify-start gap-2">
      {label && (
        <div className="text-sm font-normal leading-[21px] text-color-neutral-base-5">
          {label}
        </div>
      )}
      <div className="flex h-auto w-full flex-col items-start justify-center gap-1 rounded-lg">
        <div className="relative w-full">
          <input
            className={`text-color-neutral-strong ease w-full rounded-md border border-slate-200 bg-transparent px-3 py-2 text-sm shadow-sm transition duration-300 placeholder:text-color-neutral-base-3 hover:border-slate-300 focus:border-slate-400 focus:shadow focus:outline-none ${className}`}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            autoComplete="off"
            onFocus={onFocus}
            {...rest}
          />
          {error && (
            <div className="self-stretch text-sm font-normal leading-[21px] text-color-primary-base">
              {error}
            </div>
          )}
          {icon && (
            <img
              className="absolute right-2.5 top-2.5 h-5 w-5 text-slate-600"
              src={icon}
              alt=""
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default InputText;
